.container {
    max-width: 80%;
    margin: 0 auto;
}
.box{
    display: grid;
    padding-top: 2%;
    padding: 2%;
    padding-bottom:1% ;
    margin: 4.5rem auto;
    margin-bottom: 9rem;
    background-color:#EDF8FF ;
    border-radius: 25px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.box b{
    font-size: 2rem;
    color: #18A0FB;
    padding-left: 30px;
    padding-top: 1rem;
}
.box p{
    font-size: 1rem;
}

.box li {
    padding-bottom: 1rem;
}

.purposeContainer {
    margin: 2.5rem auto;
    
  }

/* L A R G E   V I E W */
@media only screen and (min-width: 64em) {
    .purposeContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }
  }