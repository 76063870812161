@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");

.container {
  max-width: 80%;
  margin: 1.5rem auto;
  align-items: center;
}
.left h3 {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
}

.left p {
  font-size: 1rem;
  padding-left: 0.5rem;
  line-height: 1.5rem;
}

.images {
  width: 220px;
  height: 100%;
}

/* L A R G E   V I E W */
@media only screen and (min-width: 64em) {
  .container {
    max-width: 80%;
    margin: 1.5rem auto;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 2fr 1.5fr;
    align-items: center;
  }

  .images {
  width: 100%;
  height: 300px;
  }

  .left h3 {
  text-align: left;
}
}