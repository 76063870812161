.container{
    padding: 2rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4rem;
    max-width: 80%;
    color: #ffffff;
    background-color:#082437 ;
    border-radius: 25px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.container h1{
    font-size: 2rem;
}
.container p{
    font-size: 1rem;
}