.faq-accordion {
  text-align: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  margin-bottom: 10px;
}

.faq-item {
  margin-bottom: 20px;
}

.mlh_coc {
  text-decoration: none;
}

.mlh_coc:hover {
  text-decoration: underline;
}

.faq-question {
  font-size: 1.2rem;
  background-color: #edf8ff;
  padding: 1.5rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.arrow {
  transition: transform 0.3s ease;
}

.open {
  transform: rotate(180deg);
}

.faq-answer {
  background-color: #edf8ff;
  padding: 1.5rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: left;
}

/* L A R G E   V I E W */
@media only screen and (min-width: 64em) {
  .faq-accordion {
    max-width: 50%;
  }
}
