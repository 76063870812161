.galleryContainer{
    max-width: 80%;
    margin: 0 auto;
    text-align: center;
}



.images img{
    width: 100%;
    padding: 1rem 0; 
}

/* L A R G E   V I E W */
@media only screen and (min-width: 64em) {
    .images {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 2rem;
    }  

    .images img{
        padding: 1rem; 
    }
  
  }