.container {
  text-align: center;
  background-color: #ffffff;
  border-radius: 25px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  max-width: 100%;
}

.cardStyle {
  width: 50%;
}

h3 {
  font-weight: bold;
}


/* L A R G E   V I E W */
@media only screen and (min-width: 64em) {
  .cardStyle {
    width: 60%;
  }
}