body {
  font-family: "Montserrat", sans-serif;
}

/*Titles*/

.titles {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.target {
  font-size: 2rem;
}

.titles img {
  width: 55px;
  padding: 0px;
  margin: 0px;
}

.moreInfo {
  text-align: center;
}

.cardBackground {
  background-color: #edf8ff;
  min-width: 100%;
  margin-bottom: 1rem;
  padding: 2rem 0;
  text-align: center;
}

/*individual cards*/
.cardContainer {
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  padding: 2rem 0;
}


/* L A R G E   V I E W */
@media only screen and (min-width: 64em) {
  .cardContainer {
    max-width: 80%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows:none;
    grid-column-gap: 2rem;
    grid-row-gap: 0rem;
    padding: 2.5rem 0;
  }

  .cardBackground {
    margin-bottom: 6rem;
  }

  .target {
  font-size: 2.5rem;
  }

  .titles img {
  width: 6%;
  }
}

