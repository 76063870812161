.Footerbackground {
  background-color: #082437;
  font-family: "Montserrat", sans-serif;
  margin-top: 8rem;
}
.backgroundContainer {
  color: #ffffff;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  padding: 2rem 0;
  text-align: center;
}
.logoSection{
  text-align: center;
}

.logoSection img {
  width: 50%;
}

#copyright {
  color: #ffffff;
  margin-bottom: 0;
  padding: 1rem;
  text-align: center;
  margin-top: 0;
}

.link {
  display: flex;
  flex-direction: column;
}

.link a {
  cursor: pointer;
}

.link p {
  color: #ffffff;
}

.Footerbackground a {
  text-decoration: none;
  color: #ffffff;
  padding-top: .7rem;
  padding-bottom: 0.3rem;
}

.Footerbackground a:hover {
  text-decoration: underline;
}

button {
  cursor: pointer;
}

/* L A R G E   V I E W */
@media only screen and (min-width: 400px) {
  .backgroundContainer {
    max-width: 80%;
    display: grid;
    grid-template-columns: 1fr 1.5fr .5fr 1fr;
    grid-template-rows: none;
    grid-gap: 1rem;
    margin: 0 auto;
    padding: 2rem 0;
    text-align: left;
  }

  a {
    padding-top: 0.3rem;
  }
}