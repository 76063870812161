.title h2 {
  font-size: 2.5rem;
  text-align: center;
}

.bodycontainer {
  max-width: 80%;
  margin: 1rem auto;
  padding: 0;
}

/*card container*/
.cardContained {
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr ;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  margin: 0 auto;
}


/* L A R G E   V I E W */
@media only screen and (min-width: 400px) {
  .cardContained {
    max-width: 80%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 2rem;
  }

  .bodycontainer {
  margin: 5rem auto;
  }
}


