/*Container*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.totalContainer {
  position: fixed;
  width: 100%;
  overflow: hidden;
  background-color: #ffffff;
  top: 0;
  z-index: 999;
  
}

.container {
  font-family: "Montserrat", sans-serif;
  align-items: center;
  /* display: grid; */
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;
}

.logoContainer {
  position: relative;

  flex: 1;
}
.navbar_container {
  /* display: flex;
  justify-content: center; */
  background-color: black;
  color: white;
  padding: 2rem;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;  


  /* @media screen and (max-width: 600px) {
    display: none;
  } */
}
/*Logo*/
.logoContainer img {
  /* width: 100%; */
  width: auto;
  height: auto;
  /* height: 150px
  margin: 1rem; */
  /* display: block; */
  max-width: 100px;
  min-width: 60px;
  position: fixed ;
  left: 50px;
  top: 10px;
  z-index: 1000;
  
  @media screen and (max-width: 600px) {
    /* display: none; */
    /* Make the logo smaller when on small screens */
    max-width: 50px;
    left: 20px;
  }
}

/*Button*/
.registerButtonDiv {
  display: none;
}

button {
  background-color: #18a0fb;
  border-radius: 6px;
  border: none;
  font-family: "Montserrat", sans-serif;
  padding: 1rem;
  font-weight: bold;
  cursor: pointer;
}

button a {
  color: #ffffff;
  text-decoration: none;
}

button:hover {
  background-color: #135c9d;
  transition: background-color 0.3s;
}
.linksContainer a:hover {
  color: #18a0fb;
}

/* L A R G E   V I E W */
@media only screen and (min-width: 64em) {
  .container {
    max-width: 70vw;
    margin: 0 auto;
    padding: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
  }

  /*Logo*/
  /* .logoContainer img {
    width: 150px;
  } */

  /*Links*/
  .linksContainer a {
    font-size: 1rem;
    padding: 1.5rem;
    color: #082437;
  }

  

  .registerButtonDiv {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
