@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.bodyContainer {
  background: linear-gradient(180deg, rgba(237, 248, 255, 0) 0%, rgba(237, 248, 255, 0.758086) 67.71%, #EDF8FF 100%);
  font-family: "Montserrat", sans-serif;
  color: #082437;
  padding: 2rem 0;
}

.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  text-align: center;
}

/*Right Content*/
.mainHeader{
  text-align: center;
}

.mainHeader h1{
  font-weight: 900;
  font-size: 5.5rem;
  margin: 0;
}

.mainHeader h2 {
  font-weight: 900;
  font-size: 3rem;
  padding: 0px;
  margin: 0px;
}

.mainHeader h3 {
  font-weight: 700;
  font-size: 2rem;
  margin: 0px;
}

/*Right content in Header*/
.content {
  padding: 1rem;
}

.content h2 {
  font-size: 2rem;
  font-weight: 700;
}

.content p {
  font-size: 1.1rem;
  padding-right: 0.5rem;
  line-height: 2rem;
}


/* -------------------------------------------------------- */

/* M E D I U M   V I E W */
@media only screen and (min-width: 37.5em) {

}

/* -------------------------------------------------------- */

/* L A R G E   V I E W */
@media only screen and (min-width: 64em) {
  .container {
    max-width: 80%;
    margin: 0 auto;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 0.8fr 1fr;
    grid-template-rows: none;
    padding: 4rem 0;
    text-align: left;
    padding-top: 8rem;

  }
}
